/* Importing Bootstrap SCSS file. */

@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~bootstrap/scss/bootstrap";
@import "~ngx-toastr/toastr";
@import "~@busacca/ng-pick-datetime/assets/style/picker.min.css";


/* Override bootstrap variables */
$input-border-radius: 3px;

/* App variables */
$font-family: "Roboto", sans-serif;
$primary-font-size: 13px;
$primary-color: #22a5de;
$sub-title-color: #03a9f4;
$off-white-color: #f5f5f5;
$azul-color: #005bef;
html {
  overflow: hidden;
}

body {
  font-family: $font-family !important;
  font-size: 0.8rem;
  line-height: 1.2rem;
  color: #035172;
  label:first-child {
    font-weight: 600;
  }
}

.primary-color {
  background: $primary-color;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.8 !important;
}

.sub-title-color {
  color: $sub-title-color;
}

.d-grid {
  display: grid;
}

label {
  font-size: $primary-font-size !important;
}

.label-title {
  color: #000000;
  opacity: 45%;
  line-height: 15px;
}

.btn {
  color: #fff !important;
  font-size: $primary-font-size !important;
  &:hover {
    color: #fff;
  }
}

.btn-primary {
  background-color: $primary-color !important;
  border: unset !important;
  &:hover {
    background-color: lighten($primary-color, 5%) !important;
    border-color: lighten($primary-color, 5%) !important;
  }
  &:active {
    background-color: lighten($primary-color, 5%) !important;
    border-color: lighten($primary-color, 5%) !important;
  }
  &:focus {
    box-shadow: none !important;
    outline: unset !important;
    background-color: lighten($primary-color, 5%) !important;
    border-color: lighten($primary-color, 5%) !important;
  }
}

.btn-secondary {
  background: #bcbcbc !important;
  border: unset !important;
  &:focus, &:active {
    box-shadow: none !important;
  }
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: $primary-color;
  color: white;
  text-align: center;
  p {
    margin-top: 16px;
  }
}

.form-control {
  height: 30px !important;
  font-size: $primary-font-size;
}

.form-control-sm {
  font-size: 0.8rem;
  line-height: 1.2rem;
}

.time-input {
  border: 1px solid #ced4da;
  @include border-radius($input-border-radius, 0);
  width: 110px;
  height: 30px;
}

::placeholder {
  font-size: $primary-font-size;
}

.navbar {
  padding: 7px 0 !important;
  position: relative !important;
}

.user-log-row {
  background: white;
  width: 100%;
  height: 48px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  box-shadow: 0 2px 1px -1px #2aace1, 0 1px 1px 0 #2aace1, 0 1px 3px 0 #2aace1;
  // h5 {
  // 	margin-top: 12px;
  // 	color: white;
  // 	text-transform: uppercase;
  // 	font-size: 16px;
  // 	font-weight: 600;
  // }
  .link-btn {
    padding: 7px;
    color: white !important;
    float: right;
    cursor: pointer;
    background-color: #055474;
    font-size: $primary-font-size;
    border: unset;
    border-radius: 0.25rem;
    margin: 6px 0;
    &:hover {
      background-color: lighten(#055474, 5%);
      text-decoration: none;
    }
  }
}

.index-pgs {
  padding: 50px 17px;
  display: grid;
  .fa {
    font-size: 20px;
    margin-right: 10px;
  }
  .pg-name {
    vertical-align: text-bottom;
    color: $primary-color;
  }
}

.header-title {
  .title-line {
    border-bottom: 2px solid #22a5de78;
    margin-bottom: 15px;
  }
  h5 {
    font-weight: 600;
  }
  a {
    float: right;
    color: $primary-color;
  }
}

table {
  font-size: $primary-font-size;
  border: 1px solid #dee2e6;
  tr:hover {
    color: dimgrey;
    cursor: pointer;
  }
  th,
  td {
    vertical-align: middle !important;
    padding: 3px 6px !important;
  }
}

.dropdown-toggle,
.dropdown-toggle:not(:disabled):not(.disabled):active,
.dropdown-toggle:focus,
.dropdown-toggle:hover,
.show > .btn-secondary.dropdown-toggle,
.show > .btn-secondary.dropdown-toggle:focus {
  border: 1px solid #ced4da;
  background: white;
  color: gray;
  width: 100%;
  outline: unset;
  box-shadow: unset;
}

.page-item.active .page-link {
  z-index: 0;
}

.card {
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px !important;
  border: unset !important;
  .card-header {
    background-color: #fafafa;
  }
  .card-body {
    padding: 10px !important;
  }
}

h1 {
  font-size: 32px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 28px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 15px;
}

p {
  font-size: 12px;
}

.m-t-11 {
  margin-top: 11px;
}

.shadow {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.05), 0 20px 48px rgba(0, 0, 0, 0.05),
    0 1px 4px rgba(0, 0, 0, 0.1) !important;
}

.logo {
  display: flex;
  color: white;
  font-weight: 700;
  font-size: 32px;
  text-align: center;
}

.logo-icon {
  height: 35px;
}

app-login {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: $off-white-color;
  height: 100vh;
}

.div-spinner {
  width: 100%;
  height: 100%;
  left: 0%;
  z-index: 100000;
  position: fixed;
  top: 0%;
  background-color: rgba(0, 0, 0, 0.08);
}

.spinner {
  width: 60px !important;
  height: 60px !important;
  margin-top: 22%;
}

.scroll-y {
  overflow-y: scroll;
  max-height: 570px;
}

.popup-scroll {
  overflow-y: scroll;
  max-height: 470px;
}

.inner-scroll {
  overflow-y: auto;
}

#scrollbar,
.popup-scroll,
.inner-scroll,
html {
  &::-webkit-scrollbar-track {
    background-color: $off-white-color;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: $off-white-color;
  }
  &::-webkit-scrollbar {
    height: 5px;
    background-color: $off-white-color;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $primary-color;
    box-shadow: unset;
  }
}

.ng-select {
  width: 100%;
  .ng-select-single .ng-select-container .ng-value-container {
    font-size: $primary-font-size;
  }
  .ng-clear-wrapper:hover .ng-clear {
    color: $primary-color;
  }
  .ng-clear-wrapper .ng-clear {
    margin-top: 6px;
  }
  &.ng-select-focused {
    .ng-select-container {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow !important;
    }
    .ng-dropdown-panel {
      &.ng-select-bottom {
        margin-top: 4px;
      }
      &.ng-select-top {
        margin-bottom: 4px;
      }
    }
  }
  &.ng-select-opened {
    &.ng-select-bottom, &.ng-select-top {
      > {
        .ng-select-container {
          @include border-radius($input-border-radius, 0);
        }
      }
    }    
  }
}

.ng-dropdown-panel {
  font-size: $primary-font-size;
  .ng-option-label {
    font-weight: 400 !important;
}
}

.date-field {
  width: 100px;
  height: 36px;
}

.filter-list {
  ng-select {
    display: inline-grid;
    float: right;
    &:nth-child(1) {
      width: 60px;
    }
    &:nth-child(2) {
      width: 130px;
      margin-right: 10px;
    }
  }
}

.ng-select {
  .ng-select-single .ng-select-container {
    height: 30px !important;
  }
  .ng-select-container {
    min-height: 30px !important;
  }
}

.ng-select-multiple {
  .ng-select-container {
    min-height: 100px !important;
    .ng-arrow-wrapper {
      height: 88px;
    }
  }
  .ng-value-container {
    overflow-y: auto;
    max-height: 90px;
  }
}

.nif-state label {
  padding: 26px 10px 0px 10px;
  color: grey;
}

.nif-error {
  color: red !important;
}

.nif-ok {
  color: green !important;
}

.agendas-content {
  max-height: calc(100vh - 162px);
  overflow-y: auto;
}

@media (min-width: 1366px) and (max-width: 1555px) {
  .scroll-tbl {
    overflow-y: auto;
    max-height: 285px !important;
  }
  .pdf-viewer iframe {
    height: 405px !important;
  }
  .main-agendas-menu ul li {
    padding: 11px 12px 11px 10px !important;
  }
}

@media screen and (max-width: 1555px) {
  .module-content {
    max-height: 385px !important;
  }
}

@media (min-width: 1280px) and (max-width: 1366px) {
  .footer {
    position: absolute;
  }
}

@media screen and (max-width: 1024px) {
  .md-mt-3 {
    margin-top: 20px;
  }
  .left-menu {
    width: 80% !important;
  }
  .main-agendas-menu ul li {
    img {
      width: 35px !important;
    }
    span {
      font-size: 10px !important;
    }
  }
  // .pending-contacts img {
  //   width: 12% !important;
  // }
}

@media (max-width: 560px) {
  .s-w-30 {
    width: 30%;
  }
  .s-w-50 {
    width: 50%;
  }
  .s-w-70 {
    width: 70%;
  }
  .s-m-t-10 {
    margin-top: 10px;
  }
  .s-m-b-10 {
    margin-bottom: 10px;
  }
  .user-log-row,
  .header-title {
    width: 527px;
  }
  .pagination-div {
    margin-bottom: 60px;
    width: 527px;
  }
}

.text-danger {
  font-size: 11px;
}

.pagination-div {
  height: 27px;
  margin-top: 10px;
  font-size: 14px;
  padding-left: 5px;
  .page-link {
    border: unset;
    font-size: 14px;
    padding: 5px;
  }
  .ngx-pagination {
    padding-left: 0px;
  }
  .ngx-pagination a:hover,
  .ngx-pagination button:hover {
    background: unset;
  }
}

#scroll-y,
.ng-select-multiple .ng-value-container {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: $off-white-color;
  }
  &::-webkit-scrollbar {
    width: 5px;
    background-color: $off-white-color;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $primary-color;
  }
}

.tree-view-container .custom-control-label::before,
.custom-control-label::after {
  width: 12px;
  height: 12px;
}

.red-clr {
  color: red;
}

.w-5 {
  width: 5%;
}

.sort-arrow {
  vertical-align: bottom;
  padding-left: 5px;
}

.pdf-viewer iframe {
  height: 549px;
}

.close {
  color: $primary-color !important;
  font-weight: 500;
  text-shadow: unset;
  opacity: 1;
  font-size: 25px;
  span:focus {
    outline: unset;
  }
}

.scroll-tbl {
  overflow-y: auto;
  max-height: 468px;
}

.pendiente-chip {
  background: #fcbd3e;
  color: white;
  border-radius: 17px;
  padding: 6px 20px;
  font-size: 12px;
}

.m-b-main {
  margin-bottom: 79px !important;
}

.modal-footer {
  border-top: unset;
}

.popup-title-f-s {
  font-size: 24px;
}

.view-title-f-s {
  font-size: 20px;
  font-weight: bold;
}

.sub-view-title-f-s {
  font-size: 17px;
}

.upload-btn-div {
  border-top: 2px solid #e8e8e8;
  padding-top: 17px;
}

.btn-enviar {
  width: 225px;
  height: 45px;
}

.btn-cancel {
  width: 160px;
  height: 45px;
}

.dateInput {
  .form-control {
    border-right: unset;
  }
  .input-group-text {
    height: 35px;
    background: white;
  }
}

.buscar-btn {
  height: 30px;
  width: 90%;
  margin-top: 18px;
  padding-top: 5px;
}

.details-list {
  height: 370px;
}

textarea.form-control {
  height: auto !important;
  min-height: 96px;
  font-size: $primary-font-size;
}

.owl-dt-popup-container .owl-dt-calendar {
  height: auto !important;
  .owl-dt-calendar-table {
    .owl-dt-calendar-cell-selected {
      background-color: $primary-color !important;
    }
    .owl-dt-calendar-cell-active:focus>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
    :not(.owl-dt-calendar-cell-disabled):hover>.owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
      background-color: #efefef !important;
    }
    border: unset;
  }
  .owl-dt-calendar-cell-content {
    position: relative;
    line-height: 2;
  }
  .owl-dt-control-period-button:hover>.owl-dt-control-button-content {
    background-color: #efefef !important;
  }
}

// Agenda left menu
.main-agendas-menu {
  padding-right: 0px;
  ul {
    max-height: calc(100vh - 133px);
    border: 1px solid #efefef;
    border-radius: 0px;
    li {
      display: grid;
      border: unset;
      text-align: center;
      padding: 16px 10px;
      cursor: pointer;
      img {
        width: 44%;
      }
      span {
        font-size: 12px;
        text-transform: uppercase;
        color: $primary-color;
        font-weight: 600;
        line-height: 1 !important;
        margin-top: 5px;
        word-break: break-all;
      }
      &:hover {
        background-color: #d4f2ff;
      }
    }
    .select {
      background-color: #d4f2ff;
    }
  }
}

// Agenda breadcrumb
.breadcrumb {
  padding: 0.3rem 0.75rem;
}

.agendas-breadcrumb .breadcrumb {
  border: 1px solid #efefef;
  border-bottom: 0px;
  background-color: $primary-color;
  border-radius: 2px;
  margin-bottom: 0px;
  li {
    font-size: 12px;
    color: white;
    text-transform: uppercase;
    font-weight: 600;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: ">" !important;
    color: white;
  }
}

// Agenda visualizar-citas
.agendas-content {
  border: 1px solid #efefef;
  padding: 15px;
  border-radius: 0;
  overflow-y: auto;
}

.visualizar-reserv-type,
.agenda-nuevas-citas-type {
  border: 2px solid #efefef;
  border-radius: 2px;
}

.visualizar-reserv-type {
  background-color: #fbfbfb;
}

.reserva-checkbox-row,
.agenda-row {
  background: #efefef;
  margin-left: 0px;
  padding: 5px;
}

.reserva-checkbox-row .form-check-label {
  padding-top: 2px;
}

#add-estado {
  background: white;
  color: $primary-color;
  cursor: pointer;
}

.tbl-visualizer-citars tbody tr {
  td:nth-child(9),
  td:last-child {
    color: $primary-color;
    cursor: pointer;
    padding-left: 20px !important;
  }
}

.tbl-reception .fa-print {
  cursor: pointer;
  padding-left: 20px !important;
}

.edit-cita-section {
  border: 2px solid #e6e6e6;
  border-radius: 2px;
  margin: 1px;
  padding: 10px;
  table tr {
    &:hover {
      color: inherit;
      cursor: default;
    }
    td {
      input {
        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}

.edit-cita-tbl {
  width: 100%;
  tbody .note-row {
    height: 75px;
    .form-group {
      width: 100% !important;
    }
  }
  tbody tr {
    td:first-child {
      width: 30%;
      .form-group {
        width: 400px;
      }
    }
    td:nth-child(2) {
      width: 30%;
      .form-group {
        width: 400px;
      }
    }
    td:nth-child(3) {
      width: 40%;
      .form-group {
        width: 565px;
      }
    }
  }
}

.agenda-change-div {
  background: #efefef;
  padding: 10px;
  margin: 2px;
  .citas-date {
    font-weight: 600;
    font-size: 18px !important;
  }
  button {
    margin-top: 14px;
  }
  .row:first-child {
    line-height: 30px;
    .form-group {
      margin-bottom: 0px;
    }
  }
}

.disable-cita-btn {
  background: #b3b3b39e !important;
  border-color: #b3b3b39e !important;
}

.status-btns-div {
  margin-top: 10px;
  border-radius: 2px;
  padding: 20px;
  border: 2px solid #e4e4e4;
  button {
    text-transform: uppercase;
  }
}

// switch
.switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 5px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.shedule-excepciones {
  border: 1px solid #dee2e6;
  padding: 10px 0;
  margin-right: -10px;
  .p-t-6 {
    padding-top: 6px;
  }
}

.shedule-slot-row {
  margin-right: -10px;
}

.shedule-config-tbl tbody tr {
  td {
    border-top: unset !important;
  }
  td:nth-child(9),
  td:last-child {
    color: $primary-color;
    cursor: pointer;
    padding-left: 20px !important;
  }
}

.fc-toolbar-title {
  font-size: 16px !important;
  font-weight: bold;
}

.fc-toolbar-chunk {
  .fc-today-button,
  .fc-button-group .fc-prev-button,
  .fc-button-group .fc-next-button {
    background: #acaaaa !important;
    padding: 0 9px !important;
    height: 30px;
    border: unset !important;
    margin-top: 5px !important;
    opacity: 1 !important;
    .fc-icon {
      font-size: 16px !important;
    }
  }
}

.fc .fc-col-header-cell-cushion {
  text-transform: uppercase;
}

.fc-col-header {
  a {
    color: $azul-color;
  }
}

.cdk-overlay-container {
  z-index: 2000;
}

.edit-agendar-empresa-list {
  border: 1px solid #ced4da;
  border-radius: 4px;
  padding: 5px;
  margin-left: 5px;
  ul {
    list-style: none;
    margin-bottom: 2px;
    padding-left: 3px;
    li {
      color: white;
      font-size: 12px;
      background: $primary-color;
      padding: 3px 5px;
      border-radius: 4px;
    }
  }
}

full-calendar {
  width: 100%;
}

.ng-select .ng-select-container {
  min-height: 20px;
  height: 20px;
  @include border-radius($input-border-radius, 0);
}

.ng-select.ng-select-single .ng-select-container {
  height: 20px;
  @include border-radius($input-border-radius, 0);
}

.ng-select .ng-dropdown-panel .ng-option {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial !important;
  text-transform: uppercase;
}

.ng-select .ng-arrow-wrapper {
  height: 16px;
}

select {
  background-color: white;
  border: thin solid blue;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

select {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1em + -1px),
    calc(100% - 15px) calc(1em + -1px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select:focus {
  outline: unset;
  box-shadow: unset !important;
  border-color: #e9ebee !important;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

@media (min-width: 1200px) {
  .modal-body .date-div {
    max-width: 17%;
  }
  .date-div {
    max-width: 10%;
  }
  .l-pl-9 {
    padding-left: 9px;
  }
}

.calender-box {
  padding: 14px;
  full-calendar .fc-view-harness {
    max-height: 156px;
    min-height: 235px;
  }
}

.calender-box-nuevacita {
  padding: 14px;
  full-calendar .fc-view-harness {
    height: 2380px !important;
    .fc-scroller-liquid-absolute {
      overflow: unset !important;
    }
  }
}

@media (min-width: 768px) {
  .m-pl-0 {
    padding-left: 0px;
  }
}

.status-btns-div .v-edit-btn {
  width: 100%;
}

@media (max-width: 768px) {
  .v-edit-btn {
    margin-bottom: 10px;
  }
  .visual-h-27 {
    height: 45px !important;
  }
}

.estado-status-span {
  background: #ffb837;
  color: white;
  padding: 5px 15px;
  font-weight: 600;
  border-radius: 2px;
}

.v-edit-btn:hover {
  color: #e8e8e8 !important;
}

.btn:disabled {
  cursor: no-drop;
}

.left-btn button i,
.right-btn button i {
  font-size: 15px !important;
}

.left-btn button i.fa-caret-right,
.left-btn button i.fa-caret-left,
.right-btn button i.fa-caret-right,
.right-btn button i.fa-caret-left {
  font-size: 20px !important;
}

.cerrar-btn {
  padding-right: 15px;
  .fa-sign-out:before {
    font-size: 18px;
    padding-top: 6px;
  }
}

.visual-h-27 {
  height: 27px;
}

#covid-screen-comp {
  .header-div {
    border: 1px solid #efefef;
    border-bottom: 0px;
    background-color: $primary-color;
    border-radius: 2px;
    margin-bottom: 0px;
    label {
      color: white;
      text-transform: uppercase;
      font-weight: 600;
      padding: 7px 15px;
      margin-bottom: 0px;
    }
  }
  .covid-test-div {
    border: 1px solid #efefef;
  }
  .covid-test-print-btn {
    float: right;
    border: 1px solid white;
    box-shadow: unset;
    padding: 2px 5px;
    margin: 4px 15px;
    &:hover {
      color: grey;
    }
  }
}

.min-h-100 {
  min-height: 100vh;
}

.ng-select.ng-invalid .ng-select-container {
  border: 1px solid #dc3545;
}
.time-input.ng-invalid {
  border: 1px solid #dc3545;
}

.edit-cita-tbl {
  &:not(.emp-edit) {    
    .form-group {
      margin-bottom: 0;
    }
  }
  textarea {
    min-height: unset !important;
    resize: none !important;
  }
  .form-control-plaintext .ng-select-container {
    display: block;
    width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
    .ng-arrow {
      display: none;
    }
    .ng-clear {
      color: transparent;
    }
  }
}

.p-l-19 {
  padding-left: 19px;
}

.pending-contacts {
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  img {
    width:25px;
    padding-top:8px;
  }
  .contact-bottomright {
    position: absolute;
    font-size: 10px;
    font-weight: bold;
    display: inline-block;
		padding: 0.35em 0.6em;
		background-color: #DA4453;
		line-height: 1;
		color: #fff;
		text-align: center;
		border-radius: 10rem;
		top: -7px;
    left: 18px;
  }
}

.power-BI-icon,
.analytic-BI-icon{
  position: relative; 
  padding-right: 10px; 
  cursor: pointer;
  img {
    width:25px;
    padding-top:8px;
  }
}

.country-flag {
  width: 30px;
  margin-right: 12px;
  cursor: pointer;
}

.user-log-row {
  .btn{
    width:50px;
    max-width:50px;
    border: unset;
    padding: 4px 0px;
    margin-right: 5px;
  }
  .dropdown-toggle:not(:disabled):not(.disabled):active{
    border: unset;
  }
  .dropdown-toggle::after{
    color: #22a5de !important;
    margin-left: -6px;
    vertical-align: -5px;
  }
  .dropdown-menu a{
    background: unset;
    color: #22a5de !important;
    padding: 3px 16px;
    margin: 0;
    &:hover {
      background-color: #d4f2ff !important;
    }
  }
}

.deactive-btn{
  background-color: rgb(205, 203, 203);
}

.btn-custom {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn-custom {
  color: #fff !important;
  font-size: $primary-font-size !important;
}

.btn-puestovs {
  padding: 0rem 0rem;
}

iframe
{
    border: 2px solid !important;
    border-color: #f0f0f0 !important;
}
.margin-expectation{
  margin-left: 46px;
}

//shedule config calender tab view
.agendaConfig-tab-btns {
  .btn {
    background: #d8d8d8 !important;
    color: #fff !important;
    border: 1px solid #dee2e6 !important;
    border-bottom: 0px !important;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 214px;
    padding: 5px 5px;
    position: relative;
    bottom: -1px;
    font-size: 0.8rem !important;
    font-weight: 500;
  }
  .active {
    background-color: #fff !important ;
    color: $primary-color !important;
    border-bottom-color: #fff !important;
  }
}

.agendaConfig-tab-content{
  border: 1px solid #dee2e6;
  border-radius: 3px;
  #specific {
    .fc-header-toolbar{
      .fc-toolbar-title {
        color: $primary-color;
      }
    }
  }
}

//  calender specific tab btn
#specific{
  .fc-header-toolbar{
    .fc-toolbar-title {
      color: #007bff;
      text-transform: uppercase;
    }
    .fc-button{
      background: unset;
      border: unset;
      color: grey;
      &:focus{
        box-shadow: unset;
        border: unset;
        color: #035172;
      }
      &:hover{
        color: #035172;
      }
    }
  }

}

#calendario{
  color: $link-color;
  font-weight: 600;
}

.reception-calender .fc-timegrid-event .fc-event-time{
  display: none;
}

.tippy-box[data-theme~='vmd'] {
  background-color: #495057;
  color: #ffffff;
  padding: 0px 6px;
  border-radius: 3px;
  font-size: 10px;
  &[data-placement^='bottom'] {    
    margin-top: 6px;
  }
}

.reco-details {
  .form-group {
    div {
      color: #000;
    }
  }
}

.tbl-row-action {
  color: $azul-color;
}

.pdf-icon{
  font-size: 20px !important;
  color: red;
}

.word-icon{
  font-size: 20px !important;
  color: $primary-color;
}

.puestos-vs-content {
  height: calc(100vh - 162px);
  .pane {
    border: 1px solid #efefef;
    &.top {
      background: #efefef;
    }
  }
  .content-wrap {
    --top-pane-height: 50px;
    height: calc(100% - var(--top-pane-height));
    .positions {
      border: 1px solid #efefef;
      --left-pane-header-title-and-new-position-btn-and-work-center-height: 121px;
      height: calc(100% - var(--left-pane-header-title-and-new-position-btn-and-work-center-height));
      .list-group {
        .list-group-item {
          color: #035172;
          &.active {
            background-color: $primary-color;
            color: #fff;
          }
        }
      }
    }
    .vs-position-info {
      --right-pane-header-title-height: 30px;
      height: calc(100% - var(--right-pane-header-title-height));
      .general-info {
        textarea {
          min-height: auto;
        }
      }
      .horizontal-tabs {
        --general-info-and-tabs-margin-top-and-bottom-height: 130px;
        height: calc(100% - var(--general-info-and-tabs-margin-top-and-bottom-height));
        .nav-tabs {
          .nav-link {          
            background: #d8d8d8;
            color: #fff;
            width: 214px;
            padding: 5px;
            &.active {
              background: #fff;
              color: $primary-color;
            }
          }
        }      
        .card-tabs {
          box-shadow: unset;
          --tab-navs-height: 30px;
          height: calc(100% - var(--tab-navs-height));
          .tab-pane {
              border-top: unset !important;
              border: 1px solid #dee2e6;
          }
        }
      }   
      .actions {
        position: relative;
        .action-wrap {
          position: absolute;
          right: 0;
        }
      }
      .technical-jobs-wrap {
        --technical-jobs-title-and-col-margin-height: 36px;
        height: calc(100% - var(--technical-jobs-title-and-col-margin-height));
        .technical-jobs {
          .form-check-input {
            margin-top: 0.18rem;
          }
        }
      }
      .associated-risks {
        --associated-risks-title-and-col-margin-height: 36px;
        height: calc(100% - var(--associated-risks-title-and-col-margin-height));
        .table {
          border: none;
          width: 100%;
          thead {
            th {
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              &#select_col {
                color: transparent;
              }
            }
          }
          tr {
            display: flex;
            flex-wrap: wrap;
            td {
              color: #035172;
              .badge-IN {
                background: #6a0303;
                color: #fff;
                min-width: 35px;
              }
              .badge-IM {
                background: #df3a25;
                color: #fff;
                min-width: 35px;
              }
              .badge-MO {
                background: #e99d06;
                color: rgb(14, 14, 14);
                min-width: 35px;
              }
              .badge-TO {
                background: #e9de16;
                color: rgb(14, 14, 14);
                min-width: 35px;
              }
              .badge-TR {
                background: #71d798;
                color: rgb(14, 14, 14);
                min-width: 35px;
              }
            }
            td:first-child, th:first-child {
              max-width: 40px;
              flex: 0 0 40px;
              .form-check-input {
                position: static;
              }
            }
            td:nth-child(2), th:nth-child(2) {
              max-width: 60px;
              flex: 0 0 60px;
            }
            td:nth-child(3), th:nth-child(3) {
              max-width: calc(((100% - 100px) * 20) / 100);
              flex: 0 0 calc(((100% - 100px) * 20) / 100);
            }
            td:nth-child(4), th:nth-child(4) {
              max-width: calc(((100% - 100px) * 20) / 100);
              flex: 0 0 calc(((100% - 100px) * 20) / 100);
            }
            td:last-child, th:last-child {
              max-width: calc(((100% - 100px) * 60) / 100);
              flex: 0 0 calc(((100% - 100px) * 60) / 100);
            }
          }
        }
      }
      .protocols-wrap {
        --protocols-title-and-col-margin-and-add-btn-height: 60px;
        height: calc(100% - var(--protocols-title-and-col-margin-and-add-btn-height));
        span {          
          &.underlined {
            text-decoration: underline;
          }
        }
      }
      .tests-wrap {
        --tests-title-and-col-margin-and-add-btn-height: 60px;
        height: calc(100% - var(--tests-title-and-col-margin-and-add-btn-height));
        span {          
          &.underlined {
            text-decoration: underline;
          }
        }
      }
      .delete-action {
        color: $primary-color;
        &:not(.disabled) { 
          &:hover {
            cursor: pointer;
          }
        }
        &.disabled {
          color: #d8d8d8;
          pointer-events: none;
        }
      }
    }    
    label {
      &.section-header {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        display: block;
      }
    }
  }
}
@include media-breakpoint-down(md) { 
  .puestos-vs-content {
    overflow: auto;
    .content-wrap {
      height: auto;
      .positions {
        .inner-scroll {
          max-height: 300px;
        }
      }
      .technical-jobs {
        .inner-scroll {
          max-height: 300px;
        }
      }
      .associated-risks {
        .table {
          tbody {
            height: auto !important;
            max-height: 300px;
          }
        }
      }
      .protocols-wrap {
        .inner-scroll {
          max-height: 300px;
        }
      }
      .tests-wrap {
        .inner-scroll {
          max-height: 300px;
        }
      }
    }
  }
}
.modal-backdrop {
  /* bug fix - no overlay */    
  display: none;    
}

